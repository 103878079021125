import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GiveGetFooterModule } from 'app/give-get-footer/give-get-footer.module';
import { CompatGiveGetFooterComponent } from './compat-give-get-footer.component';

@NgModule({
  imports: [GiveGetFooterModule, CommonModule],
  declarations: [CompatGiveGetFooterComponent],
})
export class CompatGiveGetFooterModule {}
