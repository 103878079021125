import { MembershipBadgeType } from '@freelancer/ui/badge';
import type { QualificationApi } from 'api-typings/common/common';
import { TimeUnitApi } from 'api-typings/common/common';
import type {
  BadgeApi,
  CorporateDetailsApi,
  MembershipPackageApi,
  ReputationApi,
  UserApi,
  UserSanctionApi,
} from 'api-typings/users/users';
import { transformCurrency } from '../currencies/currencies.transformers';
import { Enterprise } from '../enterprise/enterprise.model';
import type {
  CorporateDetails,
  UserResponsiveness,
} from '../profile-view-users/profile-view-users.model';
import type { Qualification } from '../project-view-projects/project-view-projects.model';
import { transformReputation } from '../reputation/reputation.transformers';
import { transformSkill } from '../skills/skills.transformers';
import { transformTimezone } from '../timezones/timezones.transformers';
import { transformLocation } from '../users/users-location.transformers';
import {
  transformPreferredFreelancerStatus,
  transformRisingStarStatus,
  transformUserImage,
  transformUserStatus,
} from '../users/users.transformers';
import type { Badge } from './badge.model';
import type {
  MembershipPackage,
  ProjectViewUser,
  UserSanction,
} from './project-view-users.model';

// FIXME: T267853 - optionals are a mess, 'private' transformer imports which we use
// here to reuse logic, work out appropriate default values, eliminate throws
// as much as possible
export function transformProjectViewUsers(user: UserApi): ProjectViewUser {
  if (
    !user.avatar_cdn ||
    !user.avatar_large_cdn ||
    !user.avatar_xlarge_cdn ||
    !user.chosen_role ||
    !user.id ||
    !user.location ||
    !user.primary_currency ||
    !user.registration_date ||
    !user.reputation ||
    !user.role ||
    !user.status ||
    !user.username
  ) {
    throw new ReferenceError(`Missing a required user field.`);
  }

  return {
    avatar: transformUserImage(user.avatar_cdn),
    avatarLarge: transformUserImage(user.avatar_large_cdn),
    avatarXLarge: transformUserImage(user.avatar_xlarge_cdn),
    badges: (user.badges || []).map(transformBadge),
    company: user.company,
    displayName: user.public_name || user.display_name || user.username,
    chosenRole: user.chosen_role,
    employerReputation: user.employer_reputation
      ? transformReputation(user.employer_reputation)
      : undefined,
    hourlyRate: user.hourly_rate,
    id: user.id,
    isNewFreelancer: user.reputation
      ? transformIsNewFreelancer(user.reputation)
      : undefined,
    skills: (user.jobs || []).map(transformSkill),
    location: transformLocation(user.location),
    membershipPackage: user.membership_package
      ? transformMembership(user.membership_package)
      : undefined,
    preferredFreelancer: transformPreferredFreelancerStatus(
      user.preferred_freelancer,
    ),
    primaryCurrency: transformCurrency(user.primary_currency),
    primaryLanguage: user.primary_language,
    profileUrl: `/u/${user.username}`,
    qualifications: (user.qualifications || []).map(transformQualification),
    registrationDate: user.registration_date * 1000,
    reputation: transformReputation(user.reputation),
    responsiveness: transformResponsiveness(user.responsiveness),
    role: user.role,
    status: transformUserStatus(user.status),
    tagLine: user.tagline || '',
    username: user.username,
    enterpriseIds: user.enterprise_ids,
    enterpriseInternalNames: user.enterprises,
    isDeloitteDcUser: user.enterprise_ids?.includes(Enterprise.DELOITTE_DC),
    poolIds: user.pool_ids,
    escrowComInteractionRequired: !!user.escrowcom_interaction_required,
    hasLinkedEscrowComAccount: !!user.escrowcom_account_linked,
    corporate: transformCorporateDetailsApi(user.corporate),
    sanctions: transformUserSanctionApi(user.user_sanctions),
    profileDescription: user.profile_description,
    timezone: user.timezone ? transformTimezone(user.timezone) : undefined,
    isTestUser: user.test_user,
    isShareholder: user.is_shareholder,
    isRisingStar: transformRisingStarStatus(user.rising_star_status),
    closed: user.closed || false,
  };
}

export function transformBadge(badge: BadgeApi): Badge {
  if (
    !badge.id ||
    !badge.name ||
    !badge.description ||
    !badge.time_awarded ||
    !badge.icon_url
  ) {
    throw new ReferenceError(`Missing a required timezone field.`);
  }

  return {
    id: badge.id,
    name: badge.name,
    description: badge.description,
    timeAwarded: badge.time_awarded * 1000,
    iconUrl: badge.icon_url,
  };
}

export function transformQualification(
  qualification: QualificationApi,
): Qualification {
  if (!qualification.icon_url) {
    throw new ReferenceError(`Missing an icon_url field.`);
  }

  return {
    id: qualification.id,
    name: qualification.name,
    level: qualification.level,
    type: qualification.type,
    iconUrl: qualification.icon_url.replace('/img/insignia/', ''),
    description: qualification.description,
    iconName: qualification.icon_name,
    scorePercentage: qualification.score_percentage,
    userPercentile: qualification.user_percentile,
  };
}

export function transformMembership(
  membership: MembershipPackageApi,
): MembershipPackage {
  if (!membership.name) {
    throw new Error(`Membership Package Object does not have name attribute.`);
  }
  if (!membership.id) {
    throw new Error(`Membership Package Object does not have id attribute.`);
  }

  return {
    bidLimit: membership.bid_limit,
    bidRefreshRate: membership.bid_refresh_rate,
    id: membership.id,
    skillChangeLimit: membership.job_change_limit,
    skillLimit: membership.job_limit,
    name: membership.name,
    servicePostingLimit: membership.service_posting_limit,
    timeBidRefreshed: membership.time_bid_refreshed
      ? membership.time_bid_refreshed * 1000
      : undefined,
    badgeType: transformMembershipBadgeType(
      membership.name,
      membership.duration_type,
      membership.membership_level,
    ),
  };
}

function transformMembershipBadgeType(
  membershipName?: string,
  membershipDurationType?: TimeUnitApi,
  membershipLevel?: number,
): MembershipBadgeType | undefined {
  if (!membershipName || !membershipDurationType || !membershipLevel) {
    return undefined;
  }

  // We don't show the standard and the premier subscriptions
  if (membershipName === 'standard' || membershipName === 'premier') {
    return undefined;
  }

  switch (membershipDurationType) {
    case TimeUnitApi.MONTH: {
      switch (membershipLevel) {
        case 1:
          return MembershipBadgeType.MONTHLY_LEVEL_ONE;
        case 2:
          return MembershipBadgeType.MONTHLY_LEVEL_TWO;
        case 3:
          return MembershipBadgeType.MONTHLY_LEVEL_THREE;
        case 4:
          return MembershipBadgeType.MONTHLY_LEVEL_FOUR;
        case 5:
          return MembershipBadgeType.MONTHLY_LEVEL_FIVE;
        default:
          throw new Error(
            `Unhandled monthly membership level ${membershipLevel}`,
          );
      }
    }

    case TimeUnitApi.YEAR: {
      switch (membershipLevel) {
        case 1:
          return MembershipBadgeType.ANNUAL_LEVEL_ONE;
        case 2:
          return MembershipBadgeType.ANNUAL_LEVEL_TWO;
        case 3:
          return MembershipBadgeType.ANNUAL_LEVEL_THREE;
        case 4:
          return MembershipBadgeType.ANNUAL_LEVEL_FOUR;
        case 5:
          return MembershipBadgeType.ANNUAL_LEVEL_FIVE;
        default:
          throw new Error(
            `Unhandled yearly membership level ${membershipLevel}`,
          );
      }
    }

    default:
      return undefined;
  }
}

// Keep the same as `transformIsNewFreelancer()` in project-view-users.seed.ts
function transformIsNewFreelancer(reputation: ReputationApi): boolean {
  return (
    !reputation.entire_history.reviews &&
    !reputation.earnings_score &&
    !reputation.entire_history.all
  );
}

export function transformResponsiveness(
  responsiveness: number | undefined,
): UserResponsiveness {
  switch (responsiveness) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return responsiveness;
    default:
      return undefined;
  }
}

export function transformCorporateDetailsApi(
  corporateDetails?: CorporateDetailsApi,
): CorporateDetails | undefined {
  if (!corporateDetails) {
    return undefined;
  }

  if (!corporateDetails.status || !corporateDetails.founder_id) {
    throw new Error(
      'Expecting corporate account status and founder id to be set.',
    );
  }

  return {
    status: corporateDetails.status,
    founderId: corporateDetails.founder_id,
  };
}

export function transformUserSanctionApi(
  userSanctions?: readonly UserSanctionApi[],
): readonly UserSanction[] {
  if (userSanctions === undefined) {
    return [];
  }

  return userSanctions.map(sanction => ({
    startDate: sanction.start_date * 1000,
    endDate: sanction.end_date * 1000,
    penalty: sanction.penalty,
  }));
}
