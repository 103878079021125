import type {
  CollectionActions,
  CollectionStateSlice,
  Path,
  Reference,
} from '@freelancer/datastore/core';
import {
  deepSpread,
  mergeDocuments,
  transformIntoDocuments,
  updateWebsocketDocuments,
} from '@freelancer/datastore/core';
import { isDefined } from '@freelancer/utils';
import type { PoolsCollection } from '../pools/pools.types';
import type { UsersSelfCollection } from '../users-self/users-self.types';
import { transformProjectViewUsers } from './project-view-users.transformers';
import type { ProjectViewUsersCollection } from './project-view-users.types';

export function projectViewUsersReducer(
  state: CollectionStateSlice<ProjectViewUsersCollection> = {},
  action:
    | CollectionActions<ProjectViewUsersCollection>
    | CollectionActions<UsersSelfCollection>
    | CollectionActions<PoolsCollection>,
): CollectionStateSlice<ProjectViewUsersCollection> {
  switch (action.type) {
    case 'API_FETCH_SUCCESS': {
      if (action.payload.type === 'projectViewUsers') {
        const { result, ref, order } = action.payload;
        return mergeDocuments<ProjectViewUsersCollection>(
          state,
          transformIntoDocuments(result.users, transformProjectViewUsers),
          order,
          ref,
        );
      }
      return state;
    }
    case 'API_UPDATE_SUCCESS': {
      if (action.payload.type === 'usersSelf') {
        const { delta, originalDocument } = action.payload;
        const ref: Reference<ProjectViewUsersCollection> = {
          path: {
            collection: 'projectViewUsers',
            authUid: action.payload.ref.path.authUid,
          },
        };

        if (delta.hasLinkedEscrowComAccount) {
          return updateWebsocketDocuments<ProjectViewUsersCollection>(
            state,
            [originalDocument.id],
            userProfile =>
              deepSpread(userProfile, {
                hasLinkedEscrowComAccount: delta.hasLinkedEscrowComAccount,
              }),
            ref,
          );
        }

        // Only merge the populated data to the store
        if (delta.primaryCurrency && isDefined(delta.primaryCurrency?.code)) {
          return updateWebsocketDocuments<ProjectViewUsersCollection>(
            state,
            [originalDocument.id],
            userProfile =>
              deepSpread(userProfile, {
                primaryCurrency: delta.primaryCurrency,
              }),
            ref,
          );
        }
      }

      return state;
    }

    case 'API_DELETE_SUCCESS':
      if (action.payload.type === 'pools') {
        // If the active user leaves a community (i.e. removing themselves from the pool),
        // update the current user's `projectViewUsers.poolIds` list as well, removing the
        // ID of the pool where the user left.
        const { originalDocument, ref } = action.payload;
        const userId = ref.path.authUid;

        const projectViewUserRef: Path<ProjectViewUsersCollection> = {
          collection: 'projectViewUsers',
          authUid: userId,
        };

        return updateWebsocketDocuments<ProjectViewUsersCollection>(
          state,
          [userId],
          projectViewUser =>
            deepSpread(projectViewUser, {
              poolIds: projectViewUser.poolIds?.filter(
                id => id !== originalDocument.id,
              ),
            }),
          { path: projectViewUserRef },
        );
      }

      return state;

    case 'WS_MESSAGE': {
      const userId = action.payload.toUserId;
      if (action.payload.type === 'emailVerified') {
        return updateWebsocketDocuments<ProjectViewUsersCollection>(
          state,
          [userId],
          projectViewUser => ({
            ...projectViewUser,
            ...{
              status: {
                ...projectViewUser.status,
                ...{
                  emailVerified: true,
                },
              },
            },
          }),
          { path: { collection: 'projectViewUsers', authUid: userId } },
        );
      }
      return state;
    }
    default:
      return state;
  }
}
