import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { ReferralTokenCollection } from './referral-token.types';

export function referralTokenBackend(): Backend<ReferralTokenCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => {
      const referralCode = query?.searchQueryParams.referral_code;
      const referrerUsername = query?.searchQueryParams.referrer_username;
      const bonusId = query?.searchQueryParams.bonus_id;
      return {
        endpoint: 'referral-token/getReferralToken.php',
        isGaf: true,
        params: {
          referral_code: referralCode,
          referrer_username: referrerUsername,
          bonus_id: bonusId,
        },
      };
    },
    push: undefined,
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}
