import { NgModule } from '@angular/core';
import { BackendModule } from '@freelancer/datastore/core';
import { StoreModule } from '@ngrx/store';
import { referralTokenBackend } from './referral-token.backend';
import { referralTokenReducer } from './referral-token.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('referralToken', referralTokenReducer),
    BackendModule.forFeature('referralToken', referralTokenBackend),
  ],
})
export class DatastoreReferralTokenModule {}
