import type { ReferralTokenAjaxData } from './referral-token.backend-model';
import type { ReferralToken } from './referral-token.model';

export function transformReferralToken(
  referralTokenAjax: ReferralTokenAjaxData,
): ReferralToken {
  return {
    id: referralTokenAjax.encoded_data,
  };
}
