import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@freelancer/components';
import {
  DatastoreCurrenciesModule,
  DatastoreCurrencyDetectModule,
  DatastoreEncodedUrlModule,
  DatastoreProjectViewUsersModule,
  DatastoreReferralInvitationsModule,
  DatastoreReferralTokenModule,
  DatastoreUsersModule,
} from '@freelancer/datastore/collections';
import { PipesModule } from '@freelancer/pipes';
import { TrackingModule } from '@freelancer/tracking';
import { UiModule } from '@freelancer/ui';
import { GiveGetFooterComponent } from './give-get-footer.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DatastoreCurrenciesModule,
    DatastoreCurrencyDetectModule,
    DatastoreEncodedUrlModule,
    DatastoreProjectViewUsersModule,
    DatastoreReferralInvitationsModule,
    DatastoreReferralTokenModule,
    DatastoreUsersModule,
    PipesModule,
    TrackingModule,
    TrackingModule,
    UiModule,
  ],
  declarations: [GiveGetFooterComponent],
  exports: [GiveGetFooterComponent],
})
export class GiveGetFooterModule {}
