import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { ReferralInvitationExtra } from './referral-invitations.model';
import type { ReferralInvitationsCollection } from './referral-invitations.types';

export function referralInvitationsBackend(): Backend<ReferralInvitationsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => ({
      endpoint: 'users/0.1/referral_invitations/',
      params: { bonus_id: 16 },
    }),
    push: (authUid, document, extra?: ReferralInvitationExtra) => {
      return {
        endpoint: 'users/0.1/referral_invitations/',
        method: 'POST',
        payload: {
          bonus_id: 16,
          emails: [document.email],
          referrer_username: extra?.referrerUsername,
        },
      };
    },
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}
