import { isDefined } from '@freelancer/utils';
import type {
  RehireRankDataApi,
  ReputationApi,
  ReputationDataApi,
} from 'api-typings/users/users';
import type {
  RehireRankData,
  Reputation,
  ReputationData,
  ReputationDataWithRehire,
} from './reputation.model';

export function transformReputation(reputation: ReputationApi): Reputation {
  return {
    entireHistory: transformReputationData(reputation.entire_history),
    last3Months: transformReputationData(reputation.last3months),
    last12Months: transformReputationData(reputation.last12months),
    userId: reputation.user_id,
    role: reputation.role,
    earningsScore: reputation.earnings_score,
    projectStats: reputation.project_stats,
  };
}

export function transformReputationData(
  repData: ReputationDataApi,
): ReputationData {
  return {
    overall: repData.overall,
    onBudget: repData.on_budget,
    onTimeInMs: repData.on_time,
    positive: repData.positive,
    all: repData.all,
    reviews: repData.reviews,
    incompleteReviews: repData.incomplete_reviews,
    complete: repData.complete,
    incomplete: repData.incomplete,
    earnings: repData.earnings,
    completionRate: Math.round(repData.completion_rate * 10 ** 2),
    categoryRatings: repData.category_ratings,
  };
}

export function transformReputationDataWithRehire(
  repData: ReputationDataApi,
): ReputationDataWithRehire {
  return {
    overall: repData.overall,
    onBudget: repData.on_budget,
    onTimeInMs: repData.on_time,
    positive: repData.positive,
    all: repData.all,
    reviews: repData.reviews,
    incompleteReviews: repData.incomplete_reviews,
    complete: repData.complete,
    incomplete: repData.incomplete,
    earnings: repData.earnings,
    completionRate: Math.round(repData.completion_rate * 10 ** 2),
    rehireRate: repData.rehire_rate,
    rehireRank: isDefined(repData.rehire_rank)
      ? transformRehireRankData(repData.rehire_rank)
      : undefined,
    categoryRatings: repData.category_ratings,
  };
}

export function transformRehireRankData(
  rehireRankData: RehireRankDataApi,
): RehireRankData {
  return {
    value: rehireRankData.rank,
    lastUpdated: rehireRankData.date_updated * 1000,
  };
}
