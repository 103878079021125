import type {
  CollectionActions,
  CollectionStateSlice,
} from '@freelancer/datastore/core';
import {
  mergeDocuments,
  transformIntoDocuments,
} from '@freelancer/datastore/core';
import { transformReferralToken } from './referral-token.transformers';
import type { ReferralTokenCollection } from './referral-token.types';

export function referralTokenReducer(
  state: CollectionStateSlice<ReferralTokenCollection> = {},
  action: CollectionActions<ReferralTokenCollection>,
): CollectionStateSlice<ReferralTokenCollection> {
  switch (action.type) {
    case 'API_FETCH_SUCCESS': {
      if (action.payload.type !== 'referralToken') return state;

      const { result, ref, order } = action.payload;
      return mergeDocuments<ReferralTokenCollection>(
        state,
        transformIntoDocuments(result, transformReferralToken),
        order,
        ref,
      );
    }

    default:
      return state;
  }
}
